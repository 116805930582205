// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnvironments } from './environment.common';

export const environment = {
  production: false,
  // api host
  apiHost: 'https://dev-api.leap.mdm.ferguson.com',
  //apiHost: 'https://localhost:56702', 
  // mock api host
  mockApiHost: '/assets/data',
  // api delay for mock data
  mockApiDelay: 300,
  azure_ad_tenant_id: '3c2f8435-994c-4552-8fe8-2aec2d0822e4',
  azure_ad_client_id: 'd7916627-9f17-4bbb-b3ca-b7353a538127',
  ...commonEnvironments
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
